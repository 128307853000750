import { Theme as EmotionTheme } from '@emotion/react'
import styled, { CreateStyled } from '@emotion/styled'

export const theme: Theme = {
  colours: {
    primary: '#00477F',
    primaryLighter: '#2c5697',
    primaryLighterAlt: '#305994',
    pink: '#a277a6',
    success: '#198754',
    info: '#0dcaf0',
    warning: '#ffc107',
    danger: '#dc3545',
    tableBorder: '#ddd',
    inputBorderFocus: '#66afe9',
    greyAlt: '#ccc',
    lightBlue: '#2B85DC',
  }
}

export interface Theme extends EmotionTheme {
  colours: {
    primary: string
    primaryLighter: string
    primaryLighterAlt: string
    pink: string
    success: string
    info: string
    warning: string
    danger: string
    tableBorder: string
    inputBorderFocus: string
    greyAlt: string
    lightBlue: string
  }
}

export default styled as CreateStyled
