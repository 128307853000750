import gql from 'graphql-tag'

export const TREATMENTS_INDEX_QUERY = gql`
  query TreatmentsIndex(
    $sort: SortInput
  ) {
    treatmentPlans(
      sort: $sort
    ) {
      __typename
      id
      shipDate
      treatmentDate
      customer {
        __typename
        id
        name
        country
      }
    }
  }
`
